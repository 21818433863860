import React, { useState } from "react";
import "./Feedback.scss";
import Button from "react-bootstrap/Button";
import { useAuth } from "../util/auth";
import Card from "react-bootstrap/Card";
import CloseButton from 'react-bootstrap/CloseButton';
import Form from 'react-bootstrap/Form';


function Feedback(props) {

    const [formVisible, setFormVisible] = useState(false);
    const auth = useAuth();

  return (
    <>
    {formVisible && 
    <Card className="Feedback__form">
        <Card.Body>
        <CloseButton aria-label="delete" onClick={() => {setFormVisible(false);}} />
        <Card.Title>👋 Feedback</Card.Title>
        
            <form name="feedback" method="POST" netlify data-netlify="true">
                <input type="hidden" name="form-name" value="feedback" />
                <input type="hidden" name="user" value={(auth && auth.user && auth.user.email) || "logged out"} />

                <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          style={{ height: '200px' }}
          name="message"
        />
      <Button variant="primary" type="submit" className="mt-2">
        Send &rarr;
      </Button>
            </form>
        </Card.Body>
      </Card>
    }

      <div className="Feedback__button">
          <Button 
          size="medium"
          onClick={() => {setFormVisible(true);}}
          >💌 Feedback</Button>
      </div>
    </>
  );
}

export default Feedback;