import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function ConfirmModal(props) {

  return (
    <Modal show={true} centered={true} animation={false} onHide={() => props.onCancel()}>
      <Modal.Header closeButton={true}>
        Are you sure you want to delete this item?
      </Modal.Header>
      <Modal.Body>
         <Button size="lg" variant="light" type="submit" onClick={() => props.onCancel()}>
            <span>No, cancel</span>
         </Button>
         {" "}
         <Button size="lg" variant="primary" type="submit" onClick={() => props.onDone()}>
            <span>Yes, Delete it</span>
         </Button>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmModal;
