import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function ChooseCategoryModal(props) {

  const CATEGORIES = ['Clothing', 'Equipment', 'Fancy dress', 'Shoes', 'Ski & Snowboard', 'Sport Clothing', 'Sport Shoes'];

  return (
    <Modal show={true} centered={true} animation={false} onHide={props.onClose}>
      <Modal.Header closeButton={true}>
        Select Category
      </Modal.Header>
      <Modal.Body>
        {CATEGORIES.map((category) => {
            return <span key={category.toLowerCase()}><Button className="m-1" onClick={
                () => {props.onDone(category.toLowerCase())}
            }>{category}</Button>{' '}</span>
            }
            )}

      </Modal.Body>
    </Modal>
  );
}

export default ChooseCategoryModal;
