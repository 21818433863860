import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import FormAlert from "./FormAlert";
import FormField from "./FormField";
import { useAuth } from "./../util/auth";
import { useItem, updateItem, createItem } from "./../util/db";
import UploadImage from "./UploadImage";
import ChooseCategoryModal from "./ChooseCategoryModal";

function EditItemModal(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [imageRemoteUrl, setImageRemoteUrl] = useState(null);
  const [secondaryImageRemoteUrls, setSecondaryImageRemoteUrls] = useState([]);
  const [category, setCategory] = useState(null);
  const [itemCondition, setItemCondition] = useState(null);
  const [ownerName, setOwnerName] = useState(null);
  const [ownerEmail, setOwnerEmail] = useState(null);
  const [ownerAvatar, setOwnerAvatar] = useState(null);


  const CONDITIONS = ["New", "Used - like new", "Used - good", "Used - fair"];

  const { register, handleSubmit, errors } = useForm();

  // This will fetch item if props.id is defined
  // Otherwise query does nothing and we assume
  // we are creating a new item.
  const { data: itemData, status: itemStatus } = useItem(props.id);

  useEffect(() => {
    if (itemStatus === "success" && itemData) {
      if (itemData.imageUrl && imageRemoteUrl === null) {
        setImageRemoteUrl(itemData.imageUrl);
      }
      if (itemData.secondaryImageUrls && secondaryImageRemoteUrls === null) {
        setSecondaryImageRemoteUrls(itemData.secondaryImageUrls);
      }

      if (itemData.condition) {
        setItemCondition(itemData.condition);
      }
      if (itemData.category) {
        setCategory(itemData.category);
      }
      if (itemData.ownerName) {
        setOwnerName(itemData.ownerName);
      }
      if (itemData.ownerEmail) {
        setOwnerEmail(itemData.ownerEmail);
      }
      if (itemData.ownerAvatar) {
        setOwnerAvatar(itemData.ownerAvatar);
      }
    }
    
  }, [itemStatus, imageRemoteUrl, itemData, itemData?.imageUrl, secondaryImageRemoteUrls]);

  // If we are updating an existing item
  // don't show modal until item data is fetched.
  if (props.id && itemStatus !== "success") {
    return null;
  }

  const onSubmit = (data) => {
    setPending(true);

    data = {imageUrl: imageRemoteUrl,
            secondaryImageUrls: secondaryImageRemoteUrls,
            category: category,
            ownerName: ownerName || auth.user.name,
            ownerEmail: ownerEmail || auth.user.email,
            ownerAvatar: ownerAvatar || auth.user.picture,
            condition: itemCondition,
            ...data};

    if (!itemCondition) {
      setFormAlert({
        type: "error",
        message: "Please select a condition",
      });
      setPending(false);
      return;
    }

    if (!imageRemoteUrl) {
      setFormAlert({
        type: "error",
        message: "Please add at least one photo",
      });
      setPending(false);
      return;
    }

    console.log("data", data);

    const query = props.id
      ? updateItem(props.id, data)
      : createItem({ owner: auth.user.uid, ...data });

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  console.log(props.id, category);

  if (!props.id && category === null) {
    return <ChooseCategoryModal onDone={(category) => {setCategory(category)}} onClose={() => props.onDone()} />
  }

  return (
    <Modal show={true} centered={true} animation={false} onHide={props.onDone}>
      <Modal.Header closeButton={true}>
        {props.id && <>Update</>}
        {!props.id && <>Create</>}
        {` `}Item
      </Modal.Header>
      <Modal.Body>
        {formAlert && (
          <FormAlert type={formAlert.type} message={formAlert.message} />
        )}
        
        <UploadImage
          existingImageUrl={itemData && itemData.imageUrl}
          secondaryImageUrls={itemData && itemData.secondaryImageUrls}
          onUrlsChanged={(primaryUrl, secondaryUrls) => {
            setImageRemoteUrl(primaryUrl);
            setSecondaryImageRemoteUrls(secondaryUrls);
          }}
        />

        <Form onSubmit={handleSubmit(onSubmit)}>

          <Form.Group controlId="formName">
            <FormField
              size="lg"
              name="name"
              type="text"
              placeholder="Name"
              defaultValue={itemData && itemData.name}
              error={errors.name}
              autoFocus={true}
              inputRef={register({
                required: "Please enter a name",
              })}
            />
          </Form.Group>
          <Form.Group controlId="formDescription">
            <FormField
              size="med"
              name="desc"
              type="textarea"
              placeholder="Description"
              maxlength="500"
              defaultValue={itemData && itemData.desc}
              error={errors.desc}
              inputRef={register({
                required: "Please enter a description",
              })}
            />
          </Form.Group>
          <Form.Group controlId="formSize">
            <FormField
              size="med"
              name="size"
              type="text"
              placeholder="Size"
              defaultValue={itemData && itemData.size}
              error={errors.size}
              inputRef={register({
                required: "Please enter a size",
              })}
            />
          </Form.Group>
          <Form.Group controlId="formCondition">
            {/* <FormField
              size="med"
              name="condition"
              type="text"
              placeholder="Condition"
              defaultValue={itemData && itemData.condition}
              error={errors.condition}
              inputRef={register({
                required: "Please enter a condition (e.g. new, good, lightly used)",
              })}
            /> */}
                <Form.Control 
                  error={errors.condition} as="select" name="condition"
                  defaultValue={itemCondition}
                  onChange={(e) => {setItemCondition(e.target.value);}}
                  inputRef={register({
                    required: "Please enter a condition (e.g. new, good, lightly used)",
                  })}
                  >
                  <option>Condition - Please select</option>
                  {CONDITIONS.map((cond) => <option value={cond} selected={itemCondition === cond}>{cond}</option>)}
                </Form.Control>
          </Form.Group>
          <Button size="lg" variant="primary" type="submit" disabled={pending}>
            <span>Save</span>

            {pending && (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden={true}
                className="ml-2"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditItemModal;
