import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import FormField from "./FormField";
import { useAuth } from "./../util/auth";
import { updateUser } from "./../util/db";
import { useRouter } from "./../util/router";
import Alert from 'react-bootstrap/Alert';


function PasswordSection(props) {

    const auth = useAuth();
    const router = useRouter();

    const [pending, setPending] = useState(false);
    const [password, setPassword] = useState(null);
    const [wrongPassword, setWrongPassword] = useState(false);
  
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Show pending indicator
    setPending(true);
    setWrongPassword(false);

    // TODO move check to API call
    if (password === "gojaguars") {
        updateUser(auth.user.uid, {hasEnteredPasscode: true}).then((data) => {
            router.push("/dashboard");
        })
    } else {
        setWrongPassword(true);
        setPending(false);
    }
  };

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container
        style={{
          maxWidth: "450px",
        }}
      >
        {wrongPassword && <Alert variant="danger">Wrong Access Code.</Alert>}
        <SectionHeader
          title="Enter access code"
          subtitle="Access to this site is restricted to members of the French American International School community. Please enter the access code you received from school."
          size={2}
          spaced={true}
          className="text-center"
        />

    <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formPassword">
            <FormField
                size="lg"
                name="pass"
                type="password"
                placeholder="Access code"
                onChange={(e) => {setPassword(e.target.value)}}
            />
            </Form.Group>

        <Button
            variant="primary"
            block={true}
            size="lg"
            type="submit"
            disabled={pending}
        >
            {!pending && <span>&rarr;</span>}

            {pending && (
            <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden={true}
                className="align-baseline"
            >
                <span className="sr-only">Loading...</span>
            </Spinner>
            )}
        </Button>
        </Form>

      </Container>
    </Section>
  );
}

export default PasswordSection;
