import React, { useState, useEffect } from "react";
import { storage } from "../util/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useAuth } from "./../util/auth";
import ProgressBar from 'react-bootstrap/ProgressBar';

import "./UploadImage.scss";

const MAX_ITEM_IMAGES = 6;

function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0")
}

function generateId(len) {
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
}

function UploadImage(props) {

    const auth = useAuth();

    const [imgUrl, setImgUrl] = useState(props.existingImageUrl);
    const [secondaryImageUrls, setSecondaryImageUrls] = useState(props.secondaryImageUrls || []);
    const [progresspercent, setProgresspercent] = useState(0);
    const [isUploadingMain, setIsUploadingMain] = useState(false);
    const [isUploadingSecondary, setIsUploadingSecondary] = useState(false);

    useEffect(() => {
      if (props.onUrlsChanged) {
        props.onUrlsChanged(imgUrl, secondaryImageUrls);
      }
    }, [imgUrl, secondaryImageUrls, props]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const file = e.target?.files[0];

        if (!file) return;
        if (!imgUrl) {
          setIsUploadingMain(true);
        } else {
          setIsUploadingSecondary(true);
        }

        const extension = file.name.split('.').pop();
        const userId = auth.user.uid;
        const uploadName = generateId(16) + '.' + extension;
    
        const storageRef = ref(storage, `files/${userId}/${uploadName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        uploadTask.on("state_changed",
          (snapshot) => {
            const progress =
              Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgresspercent(progress);
          },
          (error) => {
            alert(error);
            setIsUploadingMain(false);
            setIsUploadingSecondary(false);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              if (!imgUrl) {
                setImgUrl(downloadURL)
              } else {
                setSecondaryImageUrls([downloadURL, ...secondaryImageUrls]);
              }
              setIsUploadingMain(false);
              setIsUploadingSecondary(false);  
            });
          }
        );
      }

  return (
    <>
        <p>
          <label for="imageFile">Upload photo(s) of your item:&nbsp;</label>
          { secondaryImageUrls.length < MAX_ITEM_IMAGES - 1 &&
          <label className="btn btn-primary">
            {imgUrl ? "Add another photo" : "Choose Photo"}
            <input className="upload-input" type="file" id="imageFile" accept="image/*" onChange={(e) => handleSubmit(e)}/>
          </label>
          }
          {
            imgUrl && (!isUploadingMain) &&
            <>
            <span className="mx-auto d-block">
            <img className="primary-img mx-auto d-block" src={imgUrl} alt='uploaded file' height={200}/>
            <button className="upload-primary-delete btn mx-auto d-block" type="delete" onClick={(e) => {
                  if (secondaryImageUrls.length === 0) {
                    setImgUrl(null);
                  } else {
                    setImgUrl(secondaryImageUrls[0]);
                    var l = [...secondaryImageUrls];
                    l.splice(0, 1);
                    setSecondaryImageUrls(l)
                  }
                }}><i className="fas fa-trash" /></button>
            </span>    
            </>
          }
          {
            isUploadingMain &&
            <>
            <span className="mx-auto d-block">
            <img className="primary-img uploading mx-auto d-block" src="/p.png" alt='uploaded file' height={200} />
            </span>    
            </>
          }
          {(secondaryImageUrls.length > 0 || isUploadingSecondary) && <hr/>}
          {isUploadingSecondary && <span>
                <img className="secondary-img uploading" src="/p.png" alt='uploaded file' height={100} />
              </span>}
          {
            secondaryImageUrls.map((additionalUrl, i) => {
              return <span>
                <img className="secondary-img" key={"secondary-" + i} src={additionalUrl} alt='uploaded file' height={100} />
                <button className="upload-delete btn" type="delete" onClick={(e) => {
                  var l = [...secondaryImageUrls];
                  l.splice(i, 1);
                  setSecondaryImageUrls(l);
                }}><i className="fas fa-trash" /></button>
              </span>
            })
          }
          {
          (progresspercent > 0 && progresspercent < 100) &&
            <ProgressBar now={progresspercent} animated />
            // <div className='outerbar'>
            // <div className='innerbar' style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
            // </div>
          }
        </p>
    </>
  );
}

export default UploadImage;
