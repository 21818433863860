import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./FaqSection.scss";

function FaqSection(props) {
  // Object to store expanded state for all items
  const [expanded, setExpanded] = useState({});
  // Set an item's expanded state
  const setExpandedItem = (index, isExpanded) => {
    setExpanded({
      ...expanded,
      [index]: isExpanded,
    });
  };

  const items = [
    {
      question: "How do I post an item?",
      answer:
        'There are two ways to post an item. On the main page click on "Add Item" which can be found in the upper right blue bar or on the "Your Items" post click on Add Items.  Then select the category it best fits in. You will be able to add images as well as specify size and condition. Please add a brief description of the item. Then click post. Once your item has been swapped please delete your old post.',
    },
    {
      question: "What can I post?",
      answer:
        "This is a gear swap website for parents and staff of French American and International to share unwanted or no longer needed kid related gear with other community members. Items are passed on free of charge and with no reciprocity requirements. This includes things like sports shoes, clothing and equipment, ski/snowboard gear, kids clothing/shoes and costumes for those vital San Francisco dress up moments. Please ensure anything you share is in good working order and in a condition you’d be willing to accept yourself. Please make sure size, condition and any relevant information are listed.",
    },
    {
      question: "How do I contact someone about a post?",
      answer:
        "You can click on any post and click “Contact Poster”. This will enable you to send an email to the poster and they will then be able to see your email address and respond. Any communication will then be directly between you and not through the swap site. Please do not use contact emails for any purpose other than to arrange the gear swap.",
    },
    {
      question: "How do I get the item to the interested party?",
      answer:
        "You can arrange how to share the item directly with the person who is interested. French American International School is not responsible for handoff of items between parties. Please arrange the swap directly between yourselves.",
    },
    {
      question: "How do I edit or delete a post?",
      answer:
        "Under “Your Items” (your account home page) you will find a list of your current posted items. You can click on the pencil symbol to edit your post and the wastebin symbol to delete it at any time. Please remember to delete your post after an item has been successfully shared. We will also send you a reminder to do so after you've been contacted about your item.",
    },
    {
      question: "Who operates the Gear Swap?",
      answer:
        "The Parent Association has set up the Gear Swap site to facilitate sharing of gear within our community and encourage reuse. You can send us feedback on the site and its functionality here. French American and International and its Parents Association are not responsible for the items on offer or for facilitating the actual swap.",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />

        {items.map((item, index) => (
          <article
            className="FaqSection__faq-item py-4"
            onClick={() => {
              setExpandedItem(index, !expanded[index]);
            }}
            key={index}
          >
            <h4>
              <span className="text-primary mr-3">
                <i
                  className={
                    "fas" +
                    (expanded[index] ? " fa-minus" : "") +
                    (!expanded[index] ? " fa-plus" : "")
                  }
                />
              </span>
              {item.question}
            </h4>

            {expanded[index] && <div className="mt-3">{item.answer}</div>}
          </article>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
