import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import SearchSection from "../components/SearchSection";
import { useAuth } from "./../util/auth";
import { useRouter } from "./../util/router";


function IndexPage(props) {
  const auth = useAuth();
  const router = useRouter();

  if (auth.user && !auth.user.hasEnteredPasscode) {
    router.push("/password");
  }

  return (
    <>
      <Meta />
      {auth.user === false &&
      <HeroSection
        bg="info"
        textColor="light"
        size="lg"
        bgImage="https://resources.finalsite.net/images/f_auto,q_auto,t_image_size_6/v1547240838/internationalsf/qiybtmnvv3oeytcw7qtx/8753-HSAssembly.jpg"
        bgImageOpacity={0.3}
        title="Pass stuff on within the French American &amp; International community"
        subtitle="Please register or sign in to get started"
        buttonText="Get Started"
        buttonColor="light"
        buttonPath="/auth/signup"
      />}

      {auth.user && 
        <SearchSection user={auth.user}/>
      }

    </>
  );
}

export default IndexPage;
