import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Section from "./../components/Section";
import SectionHeader from "./../components/SectionHeader";
import "./ItemSection.scss";
import { useAuth } from "./../util/auth";


import TimeAgo from 'javascript-time-ago'
// English.
import en from 'javascript-time-ago/locale/en'
import { Alert } from "react-bootstrap";

import {apiRequest} from "./../util/util";

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US');

function ItemSection(props) {

  const auth = useAuth();
  const [messageSent, setMessageSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const item = props.item;

  return (
    <>
      <div className="container">
        {messageSent && <Alert variant="info">🎉 We've sent you and {item.ownerEmail} an email so you can discuss your swap!</Alert>}
        <Row>
            <Col xs={12} md={6}>
              <img src={item.imageUrl} alt={item.name} className="img-fluid m-4"/>
              {item.secondaryImageUrls && item.secondaryImageUrls.map((url, i) => 
              <img src={url} alt={item.name} className="img-fluid m-4"/>
              )}
            </Col>
            <Col xs={12} md={6}>
                    <Section>
                        <Container>
                            <SectionHeader
                            title={item.name}
                            subtitle={item.desc}
                            size={1}
                            spaced={true}
                            className="text-center"
                            />
                            <h6>Size: {item.size}</h6><h6>Condition: {item.condition}</h6>
                            <p></p>
                            <small>Posted {timeAgo.format(item.createdAt.seconds * 1000)}</small>
                            <p></p>
                            <Row className="justify-content-md-center border border-primary m-2 p-2">
                                <Col>
                                {/* {item.ownerAvatar && <Image src={item.ownerAvatar} roundedCircle={true} height={40}/>}&nbsp; */}
                                <span className="OwnerName align-middle font-weight-light">{item.ownerName}</span></Col>
                                <Col className="my-auto">
                                    <Button onClick={() => {
                                      setIsSending(true);
                                      apiRequest("send_email", "POST", 
                                        {"to": item.ownerEmail, "from": auth.user.email, "subject": item.name, "custom": item.name}).then((r) => {
                                          setMessageSent(true);
                                          setIsSending(false);
                                        })
                                    }}
                                      >{messageSent ? "Email sent!" : isSending ? 
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      /> : "Contact poster"}</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Section>
            </Col>
        </Row>
      </div>
    </>
  );
}

export default ItemSection;
