import React from "react";
import Meta from "./../components/Meta";
import DashboardSection from "./../components/DashboardSection";
import { requireAuth, useAuth } from "./../util/auth";
import { useRouter } from "./../util/router";

function DashboardPage(props) {

  const auth = useAuth();
  console.log(auth.user);
  const router = useRouter();

  if (!auth.user?.hasEnteredPasscode) {
    router.push("/password");
  }

  return (
    <>
      <Meta title="Dashboard" />
      <DashboardSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Your items"
        subtitle=""
        addOpen={router.query.add}
      />
    </>
  );
}

export default requireAuth(DashboardPage);
