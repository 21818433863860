import React from "react";
import Meta from "./../components/Meta";
import PasswordSection from "./../components/PasswordSection";
import { useRouter } from "./../util/router";
import { requireAuth } from "./../util/auth";

function PasswordPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Password" />
      <PasswordSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        type={router.query.type}
        afterAuthPath={router.query.next || "/dashboard"}
      />
    </>
  );
}

export default requireAuth(PasswordPage);
