import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Section from "./Section";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import { SearchBox } from 'react-instantsearch-hooks-web';
import { Hits, Pagination, RefinementList } from 'react-instantsearch-hooks-web';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./SearchSection.scss";
import { Button } from "react-bootstrap";
import { deleteItem } from "../util/db";
import EditItemModal from "./EditItemModal";
import TimeAgo from 'javascript-time-ago'

// English.
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

const searchClient = algoliasearch('0LEG8V2GIU', 'd8fd66acc94a81b6aa27c924885ad365');
const timeAgo = new TimeAgo('en-US');

function SearchSection(props) {

    const [updatingItemId, setUpdatingItemId] = useState(null);

    const Hit = ({ hit }) => {
        return <>
            <a className="list-group-item flex-column border mt-3" href={"/" + hit.path}>
                <div className="d-flex w-100 justify-content-left">
                    <img className="rounded" src={"https://thumb.us.davidsingleton.org/unsafe/100x100/smart/" + window.encodeURIComponent(hit.imageUrl)} alt={hit.name} height={100}/>
                    <p className="hit-text"><h5>{hit.name}</h5>{hit.desc}<br/><small>Size: {hit.size}</small><br/><small><i>Posted {timeAgo.format(hit.createdAt)}</i></small></p>
                </div>
            </a>
            {props.user.admin && <p className="admin-panel"><b>Admin Only</b> poster: {hit.ownerEmail} <Button
                      variant="link"
                      aria-label="delete"
                      onClick={(e) => {deleteItem(hit.path.split("/")[1]);window.alert("deleted - please refresh page");}}
                      className="action"
                    >
                      <i className="fas fa-trash" />
                    </Button>
                    <Button
                      variant="link"
                      aria-label="update"
                      onClick={() => setUpdatingItemId(hit.path.split("/")[1])}
                      className="action"
                    >
                      <i className="fas fa-edit" />
                    </Button>
                    </p>}

        </>
    }

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container
        style={{
          maxWidth: "850px",
        }}
      >
            <InstantSearch searchClient={searchClient} indexName="faislist">
                <Row>
                    <Col xs={0} md={4}><h4 className="search-label">Search</h4></Col>
                    <Col xs={12} md={8} >
            <SearchBox
                searchAsYouType={true}
                autoFocus={true}
                classNames={{
                  form: 'form-inline',
                  input: 'form-control form-control-lg col-sm-11',
                  submit: 'invisible',
                  reset: 'invisible',
                }}
                />
                </Col>
                </Row>
                <Row><Col xs={12} md={4}>
                    <h6 className="refine-label">Filter</h6>
            <RefinementList attribute="category"
                classNames={{
                    root: 'form-check',
                    item: '',
                    labelText: 'form-check-label',
                    checkbox: 'refine-check',
                    count: 'badge badge-light',
                }}
            /></Col>
            <Col xs={12} md={8}>
            <ul className="list-group list-group-flush">
            <Hits hitComponent={Hit} />
            <Pagination classNames={{
                root: 'btn-toolbar',
                item: 'btn',
            }}/>
            </ul>
            </Col>
            </Row>

    </InstantSearch>

      </Container>
      {updatingItemId && (
        <EditItemModal
          id={updatingItemId}
          onDone={() => setUpdatingItemId(null)}
        />
      )}
    </Section>
  );
}

export default SearchSection;
