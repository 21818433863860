export async function apiRequest(path, method = "POST", data) {
  
    return fetch(`/api/${path}`, {
      method: method,
      headers: {
        "Content-Type": "application/json"
      },
      body: data ? JSON.stringify(data) : undefined,
    })
      .then((response) => {
        console.log("->", response);
        return response.json();})
      .then((response) => {
        if (response.status === "error") {  
          throw new Error(response.code, response.message);
        } else {
          return response.data;
        }
      });
  }