import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import { useRouter } from "./../util/router";
import ItemSection from "../components/ItemSection";

import { useItemOnce } from "../util/db";
import { Spinner } from "react-bootstrap";

function ItemPage(props) {
  const router = useRouter();
  const {
    data: item,
    status: itemStatus,
  } = useItemOnce(router.query.id);

  if (itemStatus === 'loading') {
    return <Spinner />
  }

  if (!item) {
    return <><HeroSection2
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="We can't find that item."
        subtitle="Sorry, it appears that item cannot be found or is no longer available."
  /></>
  }

  return (
    <>
      <Meta title="Item" description={item.name} />

      <ItemSection item={item} />
    </>
  );
}

export default ItemPage;
