import React from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import CtaSection from "./CtaSection";
import { useAuth } from "./../util/auth";

import {apiRequest} from "./../util/util";

function DashboardSection(props) {
  const auth = useAuth();


  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>


        <CtaSection
          title="Looking for stuff?"
          subtitle="Browse recently posted stuff or search for exactly what you're looking for."
          buttonPath="/"
          buttonText="Browse items"
          buttonColor="primary"
        />

        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={1}
          spaced={true}
          className="text-center"
        />
        {auth.user && !auth.user.emailVerified &&
          <Alert
                variant="warning"
                className="text-center mx-auto mb-5"
                style={{ maxWidth: "400px" }}
              >
                Please verify your email address to start adding items. You'll find a confirmation link in an email from <b>noreply@internationalsfgearswap.com</b>.
                <span className="ml-2" role="img" aria-label="party">
                  📬
                </span>
          </Alert>
        }

      {auth.user && auth.user.emailVerified &&
        <Row>
          <Col lg={12}>
            <DashboardItems addOpen={props.addOpen} />
          </Col>
        </Row>
      }
      {
        auth.user.admin && <button onClick={()=>{
          console.log("Foo!");
          apiRequest("send_email", "POST", {"to": "david@singleton.io", "from": auth.user.email, "subject": "foo", "custom": "bar"}).then((r)=>{console.log(r)})

        }}>Foo!</button>
      }
      </Container>
    </Section>
  );
}

export default DashboardSection;
